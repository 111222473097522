import "./BuyYourCarStyles.scss";
import React, { FC, useContext, useEffect, useState } from "react";
import Layout from "../../../layout/Layout";
import { useForm } from "react-hook-form";
import CarEntity from "../../../../../domain/entities/CarEntity";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import CarCardComponent from "../../../components/carCard/CarCardComponent";
import di from "../../../../../di/DependencyInjection";
import SearchCarsUseCase, { SearchCarsUseCaseName } from "../../../../../domain/use_cases/car/SearchCarsUseCase";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import OrderByEntity from "../../../../../domain/entities/OrderByEntity";
import FilterComponent from "./components/filterComponent/FilterComponent";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Icons from "../../../assets/Icons";
import DeleteFilterComponent from "./components/deleteComponent/DeleteFilterComponent";
import { useLocation, useParams } from "react-router-dom";
import TypeVehicleContext from "../../../../../domain/providers/typeVehicle/TypeVehicleContext";
import TypeVehicleContextType from "../../../../../domain/providers/typeVehicle/TypeVehicleContextType";
import { Helmet } from "react-helmet-async";
import BrandContext from "../../../../../domain/providers/brand/BrandContext";
import BrandContextType from "../../../../../domain/providers/brand/BrandContextType";
import ColorContext from "../../../../../domain/providers/color/ColorContext";
import ColorContextType from "../../../../../domain/providers/color/ColorContextType";
import TagContext from "../../../../../domain/providers/tag/TagContext";
import TagContextType from "../../../../../domain/providers/tag/TagContextType";
import TypeOfFuelContext from "../../../../../domain/providers/typeOfFuel/TypeOfFuelContext";
import TypeOfFuelContextType from "../../../../../domain/providers/typeOfFuel/TypeOfFuelContextType";
import SeoDropdown from "./components/seoDropdown/SeoDropdown";
import {
    contentListHybridCarSeo,
    contentListHybridCarSeo2,
    contentListUsedTrucksSeo,
    contentListUsedTrucksSeo2,
    contentListHatchbackCarSeo,
    contentListHatchbackCarSeo2,
    contentListSedanCarSeo,
    contentListSedanCarSeo2,
} from "./constants";
import BannerYourCar from "./components/banner/BannerYourCar";
import { useNavigate } from "react-router-dom";
import { Financing } from "../../../components/financing/financing";
import { SkeletonCard } from "../../../components/skeletonCard/skeletonCard";
import OrangeFestModal from "../../../components/OrangeFestModal/OrangeFestModal";

const orderingOptions: OrderByEntity[] = [
    {
        label: "Relevancia",
        value: {
            keyname: "relevance",
            desc: undefined,
            warranty: undefined,
        },
    },
    {
        label: "Mayor precio",
        value: {
            keyname: "price",
            desc: true,
            warranty: undefined,
        },
    },
    {
        label: "Menor precio",
        value: {
            keyname: "price",
            desc: false,
            warranty: undefined,
        },
    },
    {
        label: "Garantía",
        value: {
            keyname: "warranty",
            desc: undefined,
            warranty: true,
        },
    },
];

const BuyYourCarPage: FC<{}> = () => {
    const navigate = useNavigate();
    const formFunctions = useForm();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
        getValues,
    } = formFunctions;
    const { typeVehicles } = useContext(TypeVehicleContext) as TypeVehicleContextType;
    const { typeVehicleName } = useParams();
    const { brands } = useContext(BrandContext) as BrandContextType;
    const { colors } = useContext(ColorContext) as ColorContextType;
    const { tags } = useContext(TagContext) as TagContextType;
    const { typeOfFuels } = useContext(TypeOfFuelContext) as TypeOfFuelContextType;
    const queryParams = new URLSearchParams(window.location.search);
    const location = useLocation();
    const [showOrangeBanner, setShowOrangeBanner] = React.useState<boolean>(
        window.localStorage.getItem("orangeFestModal") ? false : true
    );

    const [cars, setCars] = useState<CarEntity[] | undefined>(undefined);
    const [totalCars, setTotalCars] = useState(0);
    const [page, setPage] = useState<number>(queryParams.get("page") ? Number(queryParams.get("page")) : 1);
    const [openFilters, setOpenFilters] = useState<boolean>(document.body.clientWidth > 968);
    const [showListOrderBy, setShowListOrderBy] = useState(false);
    const [openOrderBy, setOpenOrderBy] = useState(false);
    const [maxPages, setMaxPages] = useState<number>(1);

    const brand = brands.find((brand) => brand.name === queryParams.get("brand"));
    const model = watch("model");
    const year = queryParams.get("year") ? queryParams.get("year") : watch("year");
    const price = watch("price");
    const typeVehicleId = queryParams.get("type_vehicle") ? queryParams.get("type_vehicle") : watch("type_vehcile_id");
    let transmission = watch("type_transmission");
    if (watch("type_transmission") == 1 || watch("type_transmission") == 0) {
        transmission = watch("type_transmission") == "1" ? "Automática" : "Manual";
    }
    console.log(transmission, "transmission");
    const tagId = queryParams.get("tag") ? queryParams.get("tag") : watch("tag_id");
    const rangeMileage = watch("km");
    const fuelId = queryParams.get("typeOfFuels") ? queryParams.get("typeOfFuels") : watch("type_fuel_id");
    const colorId = queryParams.get("color") ? queryParams.get("color") : watch("color_id");
    const plateNumber = queryParams.get("plate") ? queryParams.get("plate") : watch("plate_number");
    const orderBy = watch("orderBy");

    //searcher filters
    useEffect(() => {
        setPage(queryParams.get("page") ? Number(queryParams.get("page")) : 1);
        _handleSearch();
    }, [brand, model, year, typeVehicleId, transmission, tagId, fuelId, colorId, plateNumber, orderBy]);

    useEffect(() => {
        _handleSearch();
    }, [page]);
    useEffect(() => {
        if (
            location.pathname === "/compra-tu-carro/camionetas-usadas/" ||
            location.pathname === "/compra-tu-carro/camionetas-usadas"
        ) {
            setValue("type_vehcile_id", 2);
        }
    }, []);

    const [isTimerActive, setIsTimerActive] = useState(false);
    let timer: NodeJS.Timeout | null = null; // Inicializa el temporizador
    useEffect(() => {
        if (isTimerActive) {
            timer = setTimeout(() => {
                console.log("Temporizador finalizado");
                _handleSearch(); // Llama a la función _handleSearch después del tiempo especificado
            }, 1000);
        } else if (timer !== null) {
            clearTimeout(timer); // Detener el temporizador si isTimerActive cambia a false
        }

        return () => {
            if (timer !== null) {
                clearTimeout(timer); // Limpia el temporizador si el componente se desmonta o isTimerActive cambia
            }
        };
    }, [isTimerActive]);

    const handleToggleTimer = () => {
        setIsTimerActive((prevState) => !prevState); // Iniciar o reiniciar el temporizador
    };

    useEffect(() => {
        handleToggleTimer();
    }, [price?.min, price?.max, rangeMileage?.min, rangeMileage?.max]);

    // const _handleChangeTypeVehicle = () => {
    //     setValue("type_vehcile_id", typeVehicles.find((typeVehicle) => typeVehicle.name == typeVehicleName)?.id);
    // };

    // useEffect(() => {
    //     _handleChangeTypeVehicle();
    //     console.log(typeVehicleName, "typeVehicleName");
    // }, [typeVehicleName]);

    const _handleSearch = async () => {
        try {
            setValue("year", queryParams.get("year"));
            setValue("plate_number", queryParams.get("plate"));
            // setValue("model", queryParams.get("model"));
            // setValue("price", queryParams.get("price"));
            // setValue("type_vehcile_id", queryParams.get("type_vehicle"));
            setValue("type_transmission", queryParams.get("transmission"));
            if (queryParams.get("transmission") == "1" || queryParams.get("transmission") == "0") {
                setValue("type_transmission", queryParams.get("transmission") == "1" ? "Automática" : "Manual");
                console.log("Paso");
            }
            console.log(queryParams.get("transmission"), "transmission");
            setValue("tag_id", queryParams.get("tag"));
            // setValue("km", queryParams.get("km"));
            setValue("type_fuel_id", queryParams.get("typeOfFuels"));
            setValue("color_id", queryParams.get("color"));
            setValue("plate_number", queryParams.get("plate"));
            // setValue("orderBy", queryParams.get("orderBy"));
            const brandQuery: any = brands.filter((item: any) => item.name == queryParams.get("brand"));
            setValue("brand_id", brandQuery[0]?.id ? brandQuery[0].id : "");
            const typeQuery: any = typeVehicles.filter((item: any) => item.name == queryParams.get("type_vehicle"));
            setValue("type_vehcile_id", typeQuery[0]?.id ? typeQuery[0].id : "");
            if (watch("type_vehcile_id") == 8) setValue("type_vehcile_id", 8);
            const colorQuery: any = colors.filter((item: any) => item.name == queryParams.get("color"));
            setValue("color_id", colorQuery[0]?.id ? colorQuery[0].id : "");
            const tagQuery: any = tags.filter((item: any) => item.name == queryParams.get("tag"));
            setValue("tag_id", tagQuery[0]?.id ? tagQuery[0].id : "");

            const typeFuelsQuery: any = typeOfFuels.filter((item: any) => item.name == queryParams.get("typeOfFuels"));
            setValue("type_fuel_id", typeFuelsQuery[0]?.id ? typeFuelsQuery[0].id : "");

            const data = formFunctions.getValues();
            console.log(data, "data filter");
            window.scrollTo({
                top: 0,
                behavior: "auto",
            });
            console.warn("data.search", data.search);
            setCars(undefined);
            const response = await di
                .get<SearchCarsUseCase>(SearchCarsUseCaseName)
                .call(
                    page,
                    data.search,
                    data.brand_id,
                    data.model?.id,
                    data.year,
                    data.price,
                    data.type_vehcile_id,
                    data.type_transmission,
                    data.tag_id,
                    data.km,
                    data.type_fuel_id,
                    data.color_id,
                    data.plate_number,
                    data.orderBy,
                    data.claims,
                    data.amount_claims,
                    data.warranty,
                    data.type_warranty,
                    data.types_claims,
                    data.traction
                );
            console.log("🚀 ~ const_handleSearch= ~ response:", response);
            const repsonseCars = response.cars.sort((a, b) => {
                if (a.tag?.name === "Vehículo por ingresar" && b.tag?.name !== "Vehículo por ingresar") {
                    return 1;
                }
                if (a.tag?.name !== "Vehículo por ingresar" && b.tag?.name === "Vehículo por ingresar") {
                    return -1;
                }
                if (
                    a.tag?.name === "Vehículo por ingresar y fuera de estándar" &&
                    b.tag?.name !== "Vehículo por ingresar y fuera de estándar"
                ) {
                    return 1;
                }
                if (
                    a.tag?.name !== "Vehículo por ingresar y fuera de estándar" &&
                    b.tag?.name === "Vehículo por ingresar y fuera de estándar"
                ) {
                    return -1;
                }
                return 0;
            });
            // @ts-ignore
            repsonseCars.splice(5, 0, "1");
            setTypingTimeout(null);
            // @ts-ignore
            repsonseCars.splice(10, 0, "2");
            setCars(response.cars);
            setMaxPages(response.maxPages);
            setTotalCars(response?.count);
            window.scrollTo({
                top: 0,
                behavior: "auto",
            });
        } catch (error) {
            setCars([]);
        }
    };

    const _handleOnSubmit = async (data: any) => {
        _handleSearch();
    };

    const _handleNextPage = async () => {
        const nextPage = page + 1;
        setPage(nextPage);
        queryParams.set("page", nextPage.toString());
        window.history.replaceState(null, "", "?" + queryParams.toString());
        document.querySelector(".content_app").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const _handlePreviousPage = async () => {
        const previousPage = page - 1;
        setPage(previousPage);
        queryParams.set("page", previousPage.toString());
        window.history.replaceState(null, "", "?" + queryParams.toString());
        document.querySelector(".content_app").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const _handleClearFilters = async () => {
        reset();
        setPage(1);
        navigate("/compra-tu-carro/");
    };

    const _handlePickOrderBy = (orderByValue: OrderByEntity) => {
        setOpenOrderBy(false);
        if (watch("orderBy") == orderByValue) return;
        setValue("orderBy", orderByValue);
        setPage(1);
    };

    const [typingTimeout, setTypingTimeout] = useState(null);
    const [search, setSearch] = useState("");

    const handleInputChange = (e) => {
        setValue("search", e.target.value);
        console.warn("register", e.target.value);
        setSearch(e.target.value);
        // Limpiar cualquier temporizador anterior
        clearTimeout(null);
        handleSubmit(_handleOnSubmit);

        if (e.target.value === "") {
            handleSubmit(_handleOnSubmit);
            return;
        }

        // Establecer un nuevo temporizador
        const timeout = setTimeout(() => {
            handleSubmit(_handleOnSubmit)(e);
        }, 1000); // 1 segundo

        // Guardar el temporizador en el estado
        setTypingTimeout(timeout);
    };

    return (
        <Layout>
            <Helmet>
                <title>Compra tu Carro - wcar</title>
                <meta name="description" content="Compra tu carro usado de forma fácil y segura con wcar" />
                <meta name="keywords" content="Compra tu carro" />
                <link rel="canonical" href="https://wcar.co/compra-o-vende-tu-carro-en-colombia" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <form onSubmit={handleSubmit(_handleOnSubmit)}>
                <div className="w-100 position-relative buy_your_car_page bg_gray">
                    <div className="w-100 car_search bg_search py-3">
                        <div className="container d-flex flex-column flex-md-row px-md-5 justify-content-between align-items-center">
                            <div className="input_search">
                                <img src="/assets/icons/search.svg" className="text_orange" alt="wcar" title="wcar" />
                                <input
                                    type="text"
                                    placeholder="Buscar por marca, modelo, color..."
                                    value={search}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div className="order_by_container my-3 my-md-0 d-none d-md-block">
                                <Dropdown
                                    isOpen={openOrderBy}
                                    className=""
                                    toggle={() => (window.screen.width >= 768 ? setOpenOrderBy(!openOrderBy) : null)}
                                >
                                    <DropdownToggle caret>
                                        <span className="order-text me-3">Ordenar por:</span>
                                        <strong className="order_button hover">
                                            {orderingOptions.find(
                                                (orderItem) =>
                                                    orderItem.value?.keyname == watch("orderBy")?.value?.keyname &&
                                                    orderItem.value?.desc == watch("orderBy")?.value?.desc
                                            )?.label ??
                                                orderingOptions?.[0]?.label ??
                                                ""}
                                        </strong>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                        {orderingOptions.map((option, index) => (
                                            <DropdownItem
                                                key={index}
                                                active={watch("orderBy") === option}
                                                onClick={() => _handlePickOrderBy(option)}
                                            >
                                                {option.label}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className="car_list from_left_2">
                        <div className="row">
                            <div
                                className={`bg_white mb-md-0  ${
                                    openFilters ? "col-md-4 col-lg-3" : "d-none"
                                }  position-relative`}
                                style={{ zIndex: openFilters && window.innerWidth < 800 && 999999 }}
                            >
                                {/* <div className={"position-sticky top-0 pe-2 scroll-filter"}>
                                    <FilterComponent
                                        formFunctions={formFunctions}
                                        isOpen={openFilters}
                                        setIsOpen={setOpenFilters}
                                    />
                                </div> */}
                                <div className={"scroll-filter "}>
                                    <div className="form_underline m-4 pb-2">
                                        <p className=" mt-3 title "> Vehículos encontrados: {totalCars ?? "0"}</p>
                                    </div>

                                    <FilterComponent
                                        formFunctions={formFunctions}
                                        isOpen={openFilters}
                                        setIsOpen={setOpenFilters}
                                    />
                                </div>
                            </div>
                            {openFilters && (
                                <div
                                    className="bg-filters"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenFilters(false);
                                    }}
                                ></div>
                            )}
                            <div className={` ${openFilters ? "col-md-8 col-lg-9" : "col-md-12"} container_cars`}>
                                {((typeVehicleId === "Camioneta - SUV" && fuelId !== "Híbrido") ||
                                    location.pathname === "/compra-tu-carro/camionetas-usadas") && <BannerYourCar />}

                                {(((fuelId === "Híbrido" || typeVehicleId === "Híbrido") &&
                                    typeVehicleId !== "Camioneta - SUV") ||
                                    location.pathname === "/compra-tu-carro/carros-hibridos-colombia") && (
                                    <BannerYourCar text="Carros Híbridos Colombia" />
                                )}

                                {((typeVehicleId === "Hatchback" && fuelId !== "Híbrido") ||
                                    location.pathname === "/compra-tu-carro/Hatchback") && (
                                    <BannerYourCar text="Hatchback Colombia" />
                                )}

                                {((typeVehicleId === "Sedan" && fuelId !== "Híbrido") ||
                                    location.pathname === "/compra-tu-carro/Sedan") && (
                                    <BannerYourCar text="Carros Sedan Usados" />
                                )}

                                <div className="d-none d-md-flex justify-content-between">
                                    <div className={`mt-1 ${openFilters && "md-d-none"}`}>
                                        <div
                                            className={`btn btn_light me-3 mt-2 ${
                                                !openFilters ? "filter-btn-visible" : "filter-btn-hidden"
                                            }`}
                                            onClick={() => setOpenFilters(true)}
                                        >
                                            <img src="/assets/icons/filter.svg" alt="wcar" title="wcar" />
                                            <span className="ms-2">Filtrar</span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <DeleteFilterComponent formFunctions={formFunctions} onChange={_handleSearch} />
                                    </div>
                                    <div>
                                        <div className="btn btn_light mt-2" onClick={_handleClearFilters}>
                                            <Icons.Trash /> Limpiar filtros
                                        </div>
                                    </div>
                                </div>
                                <div className="d-block d-md-none justify-content-between">
                                    <div className="mt-1 d-flex flex-row-reverse justify-content-between">
                                        <div className="btn btn_light mt-2" onClick={() => setOpenFilters(true)}>
                                            <img src="/assets/icons/filter.svg" alt="wcar" title="wcar" />
                                            <span className="ms-2">Filtrar</span>
                                        </div>
                                        <div className="order_by_container my-3 d-md-block">
                                            <div className="d-flex align-items-center gap-2 ms-3">
                                                <p>Ordenar por: </p>
                                                <div
                                                    className="d-flex align-items-center gap-1 position-relative"
                                                    style={{
                                                        backgroundColor: "#666C8926",
                                                        padding: "8px 16px",
                                                        borderRadius: "8px",
                                                    }}
                                                    onClick={() => setShowListOrderBy(!showListOrderBy)}
                                                >
                                                    <p className="me-1">{orderBy?.label ?? "Relevancia"}</p>
                                                    <svg
                                                        width="12"
                                                        height="7"
                                                        viewBox="0 0 12 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6.0002 5.79969C5.59186 5.79969 5.18353 5.64219 4.87436 5.33303L1.07103 1.52969C0.901861 1.36053 0.901861 1.08053 1.07103 0.91136C1.24019 0.742193 1.52019 0.742193 1.68936 0.91136L5.49269 4.71469C5.7727 4.99469 6.22769 4.99469 6.5077 4.71469L10.311 0.91136C10.4802 0.742193 10.7602 0.742193 10.9294 0.91136C11.0985 1.08053 11.0985 1.36053 10.9294 1.52969L7.12603 5.33303C6.81686 5.64219 6.40853 5.79969 6.0002 5.79969Z"
                                                            fill="#666C89"
                                                            stroke="#666C89"
                                                            stroke-width="0.5"
                                                        />
                                                    </svg>
                                                    {/* List filter */}
                                                    {showListOrderBy && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: "100%",
                                                                width: "100%",
                                                                background: "#fff",
                                                                left: 0,
                                                                zIndex: 9999,
                                                                borderBottomLeftRadius: "8px",
                                                                borderBottomRightRadius: "8px",
                                                                paddingTop: "10px",
                                                                paddingBottom: "10px",
                                                                gap: "6px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            {orderingOptions.map((option, index) => (
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        zIndex: 9999,
                                                                    }}
                                                                    key={index}
                                                                    // active={watch("orderBy") === option}
                                                                    onClick={() => {
                                                                        _handlePickOrderBy(option);
                                                                        setShowListOrderBy(!showListOrderBy);
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <DeleteFilterComponent formFunctions={formFunctions} onChange={_handleSearch} />
                                    </div>
                                </div>
                                <div className="row">
                                    {cars == undefined &&
                                        Array.from({ length: 24 }).map((_, i) => (
                                            <>
                                                <div
                                                    className={`mb-3 ${
                                                        openFilters
                                                            ? "col-md-12 col-lg-6 col-xl-4"
                                                            : "col-md-4 col-xl-3"
                                                    }`}
                                                    key={i}
                                                >
                                                    <SkeletonCard />
                                                </div>
                                                {i === (openFilters ? 5 : 7) && (
                                                    <div className="d-flex justify-content-center col-12 mb-4 mt-3">
                                                        <Financing bgColor="#00fefe" textColor="#000" />
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    {cars != undefined && cars.length == 0 && <h5>NO HAY RESULTADOS</h5>}
                                    {cars != undefined &&
                                        cars.length > 0 &&
                                        cars.map((car, index) => {
                                            // @ts-ignore
                                            if (car === "1" || car === "2") {
                                                return (
                                                    <>
                                                        <div
                                                            className={`mb-3 ${
                                                                openFilters
                                                                    ? "col-md-12 col-lg-6 col-xl-4"
                                                                    : "col-md-4 col-xl-3"
                                                            }`}
                                                            key={index}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    car === "1"
                                                                        ? "/assets/buyCarBanner4.png"
                                                                        : "/assets/buyCarBanner2.jpeg"
                                                                }
                                                                className="text_orange"
                                                                alt="wcar"
                                                                title="wcar"
                                                                style={{
                                                                    width: "100%",
                                                                    maxWidth: "330px",
                                                                    height: "100%",
                                                                    maxHeight: "488px",
                                                                    borderTopLeftRadius: "0.5em",
                                                                    borderBottomLeftRadius: "0.5em",
                                                                    borderTopRightRadius: "0.5em",
                                                                    borderBottomRightRadius: "0.5em",
                                                                    objectFit: "fill",
                                                                    boxShadow: "0px 2px 4px 0px #efefef",
                                                                    margin: "auto",
                                                                }}
                                                            />
                                                        </div>
                                                        {index === (openFilters ? 5 : 7) && (
                                                            <div className="d-flex justify-content-center col-12 mb-4 mt-3">
                                                                <Financing bgColor="#00fefe" textColor="#000" />
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <div
                                                            className={`mb-3 ${
                                                                openFilters
                                                                    ? "col-md-12 col-lg-6 col-xl-4"
                                                                    : "col-md-4 col-xl-3"
                                                            }`}
                                                            key={car.id}
                                                        >
                                                            <CarCardComponent car={car} />
                                                        </div>
                                                        {index === (openFilters ? 5 : 7) && (
                                                            <div className="d-flex justify-content-center col-12 mb-4 mt-3">
                                                                <Financing bgColor="#00fefe" textColor="#000" />
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            }
                                        })}
                                </div>
                                {maxPages > 1 && (
                                    <div className="w-100 d-flex justify-content-center align-items-center my-3 ">
                                        <div className=" d-md-flex me-3 d-flex">
                                            <div
                                                className={`arrow_slider previous me-1 ${page <= 1 ? "disabled" : ""}`}
                                            >
                                                <AiOutlineArrowLeft size={20} onClick={_handlePreviousPage} />
                                            </div>
                                            <p className="d-flex align-items-center fs-6 px-2">
                                                {page} de {maxPages}
                                            </p>
                                            <div
                                                className={`arrow_slider next ms-1 ${
                                                    page >= maxPages ? "disabled" : ""
                                                }`}
                                            >
                                                <AiOutlineArrowRight size={20} onClick={_handleNextPage} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {/* SEO camoionetas usadas */}
            {((typeVehicleId === "Camioneta - SUV" && fuelId !== "Híbrido") ||
                location.pathname === "/compra-tu-carro/camionetas-usadas") && (
                <div className="my-6 pt-4 container container-drop" style={{ marginTop: "80px", marginBottom: "40px" }}>
                    <SeoDropdown
                        options={contentListUsedTrucksSeo.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                    <SeoDropdown
                        options={contentListUsedTrucksSeo2.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                </div>
            )}
            {/* SEO Sedan */}
            {typeVehicleId === "Sedan" && fuelId !== "Híbrido" && (
                <div className="my-6 pt-4 container container-drop" style={{ marginTop: "80px", marginBottom: "40px" }}>
                    <SeoDropdown
                        options={contentListSedanCarSeo.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                    <SeoDropdown
                        options={contentListSedanCarSeo2.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                </div>
            )}
            {/* SEO - Combustible: Hibrido */}
            {(fuelId === "Híbrido" || typeVehicleId === "Híbrido") && typeVehicleId !== "Camioneta - SUV" && (
                <div
                    className="my-6 pt-4 container container-drop"
                    style={{
                        marginTop: "80px",
                        marginBottom: "40px",
                    }}
                >
                    <SeoDropdown
                        options={contentListHybridCarSeo.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                    <SeoDropdown
                        options={contentListHybridCarSeo2.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                </div>
            )}
            {/* SEO Hatchback */}
            {((typeVehicleId === "Hatchback" && fuelId !== "Híbrido") ||
                location.pathname === "/compra-tu-carro/Hatchback") && (
                <div className="my-6 pt-4 container container-drop" style={{ marginTop: "80px", marginBottom: "40px" }}>
                    <SeoDropdown
                        options={contentListHatchbackCarSeo.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                    <SeoDropdown
                        options={contentListHatchbackCarSeo2.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                </div>
            )}
            {/* {showOrangeBanner && <OrangeFestModal setIsOpen={setShowOrangeBanner} />} */}
        </Layout>
    );
};

export default BuyYourCarPage;
